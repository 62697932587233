<template>
  <select-popup
    :size="size"
    :closePopup="closePopup"
    :showLoading="showLoading"
    class="menu-popup"
  >
      <div v-if="mobileView" class="mobile-view close-div" @click="closePopup()">
          <img
              src="@/assets/icons/detail_close_icon.svg"
              alt="closes"
              class="header-close"
              loading="lazy"
            />
      </div>
    <div class="profile">
      <div class="profile-image">
        <img
          v-if="picture"
          :src="picture"
          alt="vlt-prof"
          class="profile-img-responsive"
          loading="lazy"
        />
        <img v-else :src="defaultPicture" alt="vlt-prof" loading="lazy" />
      </div>
      <p class="user-name">{{ appUser.subscribername }}</p>
      <p class="user-email">{{ appUser.email }}</p>
      <div style="margin-top: 10px" v-if="displaySubscribe">
        <div class="login-signup-button vlt-btn" @click="actSubscribeClicked">
          SUBSCRIBE
        </div>
      </div>
    </div>
    <ul class="menu-ul">
      <div class="row prof-row li-elem" @click="profileCB">
        <div class="col-xlg-10 col-lg-10 col-sm-10 col-xs-10 col-md-10">
          <p class="meta-link">Profile</p>
        </div>
        <div class="col-xlg-2 col-lg-2 col-sm-2 col-xs-2 col-md-2">
          <img
            src="@/assets/icons/right_arrow.svg"
            alt="right-arrow"
            loading="lazy"
          />
        </div>
      </div>
      <div class="row logout li-elem" @click="logoutCB">
        <div class="col-xlg-10 col-lg-10 col-sm-10 col-xs-10 col-md-10">
          <p class="meta-link logout-label">Logout</p>
        </div>
        <div class="col-xlg-2 col-lg-2 col-sm-2 col-xs-2 col-md-2">
          <logout-svg-vlite></logout-svg-vlite>
          <!-- <img v-if="appVendorId === 't9hHjPZW'" src="@/assets/icons/logout_icon.svg" alt="logout icon" loading="lazy" />
          <img v-else-if="appVendorId === 'rcvQ7Koa'" src="@/assets/icons/logout_icon_vlite.svg" alt="logout icon" loading="lazy" />
          <img v-else-if="appVendorId === 'Fx93mhiV'" src="@/assets/icons/logout_icon_ok.svg" alt="logout icon" loading="lazy" /> -->
        </div>
      </div>
    </ul>
  </select-popup>
</template>

<script>
import appMixins from "@/mixins/appMixins";
import { formatDateString } from '@/mixins/dateSupport';
import GoogleAnalytics from "@/mixins/GoogleAnalytics";
import {_googleId} from "@/module/vendor-config";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { EventBus } from "@/main";
export default {
  name: "ProfieMenu",
  props: ["size", "closePopup", "showLoaderCB"],
  data() {
    return {
      showLoading: false,
      defaultPicture: require("@/assets/icons/user-default.svg"),
      loginMethod: null,
      subscribeStatus: undefined,
      displaySubscribe: false,
      flagAutologin: true,
      appVendorId: "",
      mobileView:false
    };
  },
  computed: {
    ...mapGetters(["subscriberId", "picture", "appUser", "appConfig"]),
  },
  methods: {
    ...mapActions(["actGetPlans"]),
    logoutCB() {
      let GA = {
        MenuItem: "SignOut",
      };

      this.MenuEvent(GA);
      this.showLoading = true;

      let loginType = JSON.parse(localStorage.getItem("loginType"));

      if (loginType && loginType.type === "FB") {
        FB.logout(function (response) {
          // user is now logged out
        });
      } else if (loginType && loginType.type === "GOOGLE") {
        // gapi.auth2.getAuthInstance().disconnect();
        // let auth2 = gapi.auth2.getAuthInstance();
        // auth2.signOut().then(() => {
        //   auth2.disconnect();
        // });

        gapi.load("auth2", () => {
          let auth2 = gapi.auth2.init({
            client_id: _googleId,
            cookiepolicy: "single_host_origin",
            scope: "profile",
            prompt: "select_account consent",
          });

          auth2.signOut().then(() => {
            auth2.disconnect();
          });
        });
      }

      EventBus.$emit("logOut");
      this.closePopup();
    },
    profileCB() {
      let GA = {
        MenuItem: "Profile",
      };

      this.MenuEvent(GA);

      this.closePopup();
      this.$router.push({
        name: "Profile",
      });
    },
    actSubscribeClicked() {
      let data = {};
      if (
        this.appConfig.featureEnabled.subscription &&
        this.appConfig.featureEnabled.purchase
      ) {
        // data = {
        //   intermediate: true
        // };
        data = {
          subscription: true,
          menuClicked: true,
        };
      } else {
        data = {
          subscription: true,
          menuClicked: true,
        };
      }
      this.closePopup();
      EventBus.$emit("paymentInitPopup", data);
    },
    actCheckSubscribeDisplay() {
      if (this.appConfig.featureEnabled.subscription) {
        let data = {};
        data.subscriberid = this.subscriberId;
        this.actGetPlans(data)
          .then((response) => {
            if (!response.error) {
              if (
                response.planstatus !== "ACTIVE" &&
                response.planstatus !== "CANCEL"
              ) {
                if (!this.expiryDetails(response.expirydate)) {
                  this.displaySubscribe = true;
                } else {
                  this.displaySubscribe = false;
                }
              } else {
                this.displaySubscribe = false;
              }
            } else {
              this.displaySubscribe = true;
            }
          })
          .catch((error) => {
            this.displaySubscribe = true;
            console.log("error ", error);

            if (error.status === 401) {
              if (this.flagAutologin) {
                this.commonAutoLogin(this.actCheckSubscribeDisplay);
                this.flagAutologin = false;
                return;
              }
              // this.unauthorisedHandler();
            }
          });
      } else {
        this.displaySubscribe = false;
      }
      if (this.subscribeStatus && this.appConfig.featureEnabled.subscription) {
        this.displaySubscribe = true;
      }
    },
    mobileViewToggle(value){
      this.mobileView=value;
    }
  },
  created() {
    window.loadGoogleApi && window.loadGoogleApi();
    this.appVendorId = JSON.parse(localStorage.getItem("appVendor")).vendorId;
    this.actCheckSubscribeDisplay();

    EventBus.$on("planPurchased", this.actCheckSubscribeDisplay);
    if (localStorage.getItem("LoginType")) {
      let loginType = JSON.parse(localStorage.getItem("loginType"));
      this.loginMethod = loginType.type;
    }

    if (this.appUser) {
      if (this.appUser.expirydate) {
        let currentDate = new Date();
        let date = new Date( formatDateString( this.appUser.expirydate ));

        if (date < currentDate) {
          this.subscribeStatus = true;
        } else {
          this.subscribeStatus = false;
        }
      } else {
        this.subscribeStatus = true;
      }
    }

    EventBus.$on("logoutError", (error) => {
      let GA = {
        "Event Label": "Failure",
        Status: "FAILURE",
        ErrorCode: error.error,
        ErrorMessage: error.reason,
      };
      this.logoutEvent(GA);
      // this.unsubscribeEvent();

      if (error.error === 401) {
        this.unauthorisedHandler();
      }
    });
    EventBus.$on("logoutSuccess", () => {
      let GA = {
        "Event Label": "Success",
        Status: "SUCCESS",
      };
      this.logoutEvent(GA);
      // this.unsubscribeEvent();
      this.$router.push({ name: "Home" });
    });
     EventBus.$on('toggle-dropdown-view',this.mobileViewToggle);
  },
  beforeDestroy() {
    EventBus.$off("planPurchased", this.actCheckSubscribeDisplay);
     EventBus.$off('toggle-dropdown-view',this.mobileViewToggle);
  },
  components: {
    "select-popup": () =>
      import(
        /* webpackChunkName: "selectPopup" */ "@/components/Shared/templates/selectPopup.vue"
      ),
    "logout-svg-vlite": () =>
      import(
        /* webpackChunkName: "logoutIconVlite" */ "@/components/svgComponents/logout_icon.vue"
      ),
  },
  mixins: [appMixins, GoogleAnalytics],
};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";
@import "~sass/modules/hovereffects";

.mobile-view.close-div{
  text-align: right;
}
.menu-ul {
  img,
  .svgdiv {
    width: 25px;
    height: 15px;
    margin-top: 6px;
    cursor: pointer;
  }
  .meta-link {
    cursor: pointer;
    font-size: 1rem;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    color: $font-clr-gray;
    line-height: 1.5rem;
  }
  &:hover .li-elem {
    background: inherit;
  }
  .li-elem:hover {
    background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
  }
}
.profile-image {
  text-align: center;
  .profile-img-responsive {
    width: 80px;
    cursor: pointer;
    border-radius: 50%;
  }
}
.user-name {
  text-align: center;
  text-transform: capitalize;
  //text-decoration: underline;
  font-size: 1rem;
  font-family: $font-family;
  font-weight: $font-weight-semibold;
  color: $font-clr-gray;
  letter-spacing: 0px;
  line-height: 1.5rem;
  text-align: left;
  opacity: 1;
}
.user-email {
  font-family: $font-family;
  font-weight: $font-weight-regular;
  color: $font-clr-gray;
  letter-spacing: 0px;
  font-size: 0.8rem;
  text-align: left;
  opacity: 1;
}
.profile {
  // margin-bottom: 15px;
  padding: 20px;
  p {
    text-align: center;
  }
}
.prof-row {
  padding: 10px 5px;
  background: $clr-bg-gray-dark 0% 0% no-repeat padding-box;
  opacity: 1;
}
.logout {
  padding: 10px 5px;
}
.logout-label {
  color: $theme-primary !important;
}
.login-signup-button{
    @include buttonHover;
    font-size: 1rem;
    font-family: $font-family;
    font-weight: $font-weight-semibold;
    text-transform: uppercase;
    text-align:center;
    vertical-align: middle;
    line-height: 2.25rem;
    height: 2.25rem;
    cursor:pointer
}
</style>